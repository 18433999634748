import {AppointmentState, AppointmentErrors, AppointmentFormErrors} from './types'
import {create} from 'zustand'
import {deleteData, fetchData, postData, putData} from '@/common'

/**
 * In UI form we have 3 fields date, startHour and endHour
 * but backend provides us startDate & endDate
 * we have to convert from on structure to another
 * same issue with errors
 */
export const pickDateHours = (startDate: number, endDate: number) => {
  const timeOpts = {hour: `2-digit`, minute: `2-digit`, hour12: false} as Intl.DateTimeFormatOptions
  const date = new Date(startDate).toLocaleDateString(`en-CA`)
  const startTime = new Date(startDate).toLocaleString(`en-CA`, timeOpts)
  const endTime = new Date(endDate).toLocaleString(`en-CA`, timeOpts)
  return {date, startTime, endTime}
}

export const toFormErrors = (apiError: AppointmentErrors): AppointmentFormErrors => {
  if (!apiError) return {}
  const {startDate, endDate, ...rest} = apiError
  const date = startDate || endDate
  const startTime = startDate
  const endTime = endDate
  return {date, startTime, endTime, ...rest}
}

export const useAppointment = create<AppointmentState>((set, get) => ({
  appointments: [],
  currentId: null,
  enabledPastAppointments: false,
  errors: null,
  isLoading: false,

  getCurrentAppointment: () => {
    const {appointments, currentId} = get()
    const appointment = appointments.find(({id}) => id === currentId)
    if (appointment) {
      const {startDate, endDate, ...rest} = appointment
      const dateHours = pickDateHours(startDate, endDate)
      return {...rest, startDate, ...dateHours}
    }
    return appointment
  },

  loadData: () => {
    const {enabledPastAppointments} = get()
    const query = {'past-appointment': String(enabledPastAppointments)}
    set({'isLoading': true, 'errors': null})
    fetchData(`/api/appointments/`, query)
      .then(({data, errors}) => {
        data && set({'appointments': data})
        errors && set({errors})
      })
      .finally(() => set({'isLoading': false}))
  },

  createAppointment: ({date, startTime: start, endTime: end, onComplete, ...rest}) => {
    const startDate = new Date(`${date}T${start.padStart(5, `0`)}:00`).getTime()
    const endDate = new Date(`${date}T${end}:00`).getTime()
    const payload = {...rest, startDate, endDate}
    const pushData = rest.id ? putData : postData
    set({'isLoading': true, 'errors': null})
    pushData(`/api/appointments/?sendNotification=true`, payload)
      .then(({errors}) => {
        set({'errors': toFormErrors(errors)})
        !errors && onComplete()
      })
      .finally(() => get().loadData())
  },

  cancelAppointment: (onComplete) => {
    const {currentId} = get()
    if (!currentId) return
    set({'isLoading': true})
    deleteData(`/api/appointments/${currentId}?sendNotification=true`)
      .then(onComplete)
      .finally(() => get().loadData())
  },
  set,
}))

export * from './types'
