import 'public/style-pro.css'
import './video-chat.scss'
import './styles.scss'
import {AppProps} from 'next/app'
import {I18nProvider} from '@lingui/react'
import {UiProvider} from '@doctena-org/ui-configs'
import {i18n} from '@lingui/core'
import {useApp} from '@/store'
import React, {useEffect, FC} from 'react'
import {useRouter} from 'next/router'
import {WhereByEmbed} from "@/contents/video-call"

declare global {
  namespace JSX {
    // the allowed `whereby-embed` elements
    interface IntrinsicElements {
      'whereby-embed': WhereByEmbed;
    }
  }
}

const App: FC<AppProps> = props => {
  const {Component: Content, pageProps} = props
  const {locale, loadLocale} = useApp()
  const {locale: routerLocale} = useRouter()

  useEffect(() => {
    if (routerLocale) {
      if ([`it`, `pt`].includes(routerLocale)) loadLocale(`en`)
      loadLocale(routerLocale)
    }
    else {
      loadLocale(locale)
    }
    i18n.activate(locale)
  }, [locale, routerLocale])

  return (
    <UiProvider value={{prefix: `ui`, urlIconSprites: `/icon-sprites.svg`}}>
      <I18nProvider i18n={i18n}>
        <Content {...pageProps}/>
      </I18nProvider>
    </UiProvider>
  )
}

export default App
