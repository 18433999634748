import {TokenValidationResponse, VideoCallState} from './types'
import {create} from 'zustand'
import {fetchData} from '@/common'
import {useApp} from '@/store'

export const useVideoCall = create<VideoCallState>((set, get) => ({
  accessToken: ``,
  canStart: [false, false],
  endDate: ``,
  errors: null,
  isLoading: false,
  participantName: ``,
  role: ``,
  startDate: ``,
  roomUrl: ``,

  validateToken: token => {
    set({'errors': null, 'isLoading': true, 'startDate': ``})
    fetchData(`/api/call/validate/${token}`)
      .then(({data, errors}: TokenValidationResponse) => {
        if (errors) {
          set({errors})
          return
        }
        if (!data) return
        const {set: setApp} = useApp.getState()
        const {role, ...rest} = data
        setApp({'userType': role})
        set(state => ({...state, ...rest, role, errors: null}))
      })
      .finally(() => set({'isLoading': false}))
  },
  set,
}))

export * from './types'
