import {Path, UseFormSetError} from 'react-hook-form'
import {useEffect} from 'react'

type ApiErrors = Record<string, string> | null

// setErrors converts API errors to react-hook-form errors.
// TODO: Remove ts-ignore and call for help for this one, but I need to skip it for now
// @ts-ignore
export const setErrors = <T>(errors: ApiErrors, setError: UseFormSetError<T>) => {
  useEffect(() => {
    Object
      .entries(errors || {})
      .forEach(([key, value]) => {
        setError(key as Path<T>, {message: value})
      })
  }, [errors])
}
