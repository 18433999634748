import router from 'next/router'
import {AuthState} from './types'
import {create} from 'zustand'
import {getCookie, postData, setCookie} from '@/common'
import {useApp} from '@/store'

export const useAuth = create<AuthState>((set, get) => ({
  errors: null,
  isLoading: false,
  isLogged: Number(getCookie(`authorized`)) >= 1,
  recoveryPasswordStep: 1,
  resetPasswordStep: 1,

  login: (payload) => {
    const {set: setApp} = useApp.getState()
    set({'errors': null, 'isLoading': true})
    postData(`/api/login/`, payload)
      .then(({data, errors}) => {
        if (errors) {
          set({errors})
          return
        }
        set({'errors': null, 'isLogged': true})
        setCookie(`userEmail`, data.username)
        router.reload()
      })
      .finally(() => set({'isLoading': false}))
  },

  logout: () => {
    fetch(`/api/logout/`).finally(() => {
      window.location.href = `/login/`
    })
  },

  recoverPassword: payload => {
    set({'errors': null, 'isLoading': true, 'recoveryPasswordStep': 1})
    postData(`/api/reset-password/`, payload)
      .then(({errors}) => {
        if (errors) {
          set({errors})
          return
        }
        set({'errors': null, 'isLogged': false, 'recoveryPasswordStep': 2})
      })
      .finally(() => set({'isLoading': false}))
  },

  resetPassword: payload => {
    postData(`/api/update-password/`, payload)
      .then(({errors}) => {
        if (errors) {
          set({errors})
          return
        }
        set({'errors': null, 'isLogged': false, 'resetPasswordStep': 3})
        setCookie(`authorized`, `0`, 0)
        setCookie(`jwt`, ``, 0)
      })
      .finally(() => set({'isLoading': false}))
  },
  set,
}))

export * from './types'
