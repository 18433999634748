type SetCookie = (name: string, value: string, expiration?: number) => void
type GetCookie = (name: string) => string

export const getCookie: GetCookie = name => {
  if (typeof window === `undefined`) return ``
  return decodeURIComponent(document.cookie)
    ?.split(`;`)
    ?.map(str => str.trim())
    ?.find(str => str.startsWith(`${name}=`))
    ?.match(/\w+=(.+)/i)
    ?.[1]
  ?? ``
}

const YEAR = 365 * 24 * 60 * 60 * 1000

export const setCookie: SetCookie = (name, value, expiration = YEAR) => {
  if (typeof window === `undefined`) return
  const date = new Date()
  date.setTime(date.getTime() + expiration)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/;SameSite=None;Secure`
}
