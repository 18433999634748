import {AccountState} from './types'
import {create} from 'zustand'
import {deleteData, fetchData, postData, putData} from '@/common'

export const useAccount = create<AccountState>((set, get) => ({
  isLoading: false,
  errors: null,
  currentId: null,
  accounts: [],

  getCurrentAccount: () => {
    const {accounts, currentId} = get()
    return accounts?.find(({id}) => id === currentId)
  },

  loadData: () => {
    set({'isLoading': true})
    fetchData(`/api/users/`)
      .then(res => {
        set({'accounts': res.data})
      })
      .finally(() => set({'isLoading': false}))
  },

  createAccount: ({onComplete, ...payload}) => {
    const pushData = payload.id ? putData : postData
    set({'isLoading': true})
    set({'errors': null})
    pushData(`/api/users/?sendNotification=true`, payload)
      .then(({errors}) => {
        set({errors})
        !errors && onComplete()
      })
      .finally(() => get().loadData())
  },

  deleteAccount: (onComplete) => {
    const {currentId} = get()
    if (!currentId) return
    set({'isLoading': true})
    deleteData(`/api/users/${currentId}/`)
      .then(({errors}) => {
        if (errors) {
          set({errors})
          onComplete(3)
          return
        }
        onComplete(2)
      })
      .finally(() => get().loadData())
  },
  set,
}))

export * from './types'
