import {AppState} from './types'
import {create} from 'zustand'
import {getCookie, setCookie} from '@/common'
import {i18n} from '@lingui/core'
import {setLocale} from 'yup'
import {t} from '@lingui/macro'

const USER_TYPES = {
  1: `DOCTOR`,
  2: `ADMIN`,
}

// Function is used to set translations of the yup validator.
// When language changes we need to update validation messages.
const setYupLocale = () => (
  setLocale({
    mixed: {
      default: t({id: `Required field`}),
      required: t({id: `Required field`}),
      notType: t({id: `Invalid value`}),
    },
    string: {
      email: t({id: `Invalid email address`}),
      min: t({id: `Must be at least {0} characters`, message: `Must be at least ${`\${min}`} characters`}),
    },
    date: {
      min: t({id: `Date field must be later than {min}`, message: `Date field must be later than ${`\${min}`}`}),
    },
  })
)

export const useApp = create<AppState>((set, get) => ({
  isLoading: false,
  userEmail: getCookie(`userEmail`) || ``,
  userType: USER_TYPES[getCookie(`authorized`)] || null,
  locale: `en`,
  loadLocale: locale => {
    const {isLoading} = get()
    if (isLoading || i18n.locale === locale) return
    set({isLoading: true})
    import(`@/locales/${locale}/messages`)
      .then(({messages}) => {
        set({isLoading: false, locale})
        setCookie(`NEXT_LOCALE`, locale)
        i18n.load(locale, messages)
        i18n.activate(locale)
        setYupLocale()
      })
  },
  set,
}))

export * from './types'
