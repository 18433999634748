import {ModalState} from './types'
import {create} from 'zustand'

export const useModal = create<ModalState>((set, get) => ({
  accountDelete: false,
  accountForm: false,
  appointmentCancel: false,
  appointmentForm: false,
  screenStep: 1,
  set: (modalState) => set(modalState),
  isVisible: (modalId) => useModal.getState()[modalId],
  openModal: (modalId) => set({[modalId]: true}),
  closeModal: (modalId) => set({[modalId]: false, 'screenStep': 1}),
}))

export * from './types'
